import React from 'react';
import Script from 'next/script';

export default function WMFScripts() {
    const configEnv = process.env.NEXT_PUBLIC_ENV
        ? process.env.NEXT_PUBLIC_ENV
        : 'local';

    return (
        <>
            {configEnv === 'production' ? (
                <>
                    <Script
                        id="google-analytics"
                        src="https://www.googletagmanager.com/gtag/js?id=GTM-PMMW2QG"
                        async={true}
                        strategy="lazyOnload"
                        onLoad={() => {
                            setTimeout(() => {
                                (function (w, d, s, l, i) {
                                    w[l] = w[l] || [];
                                    w[l].push({
                                        'gtm.start': new Date().getTime(),
                                        event: 'gtm.js',
                                    });
                                    let f = d.getElementsByTagName(s)[0],
                                        j = d.createElement(s),
                                        dl = l != 'dataLayer' ? '&l=' + l : '';
                                    j.async = true;
                                    j.src =
                                        'https://www.googletagmanager.com/gtm.js?id=' +
                                        i +
                                        dl;
                                    f.parentNode.insertBefore(j, f);
                                })(
                                    window,
                                    document,
                                    'script',
                                    'dataLayer',
                                    'GTM-PMMW2QG',
                                );
                            }, 3000);
                        }}
                    />
                    <Script
                        id="fb-pixel"
                        strategy="lazyOnload"
                        async={true}
                        dangerouslySetInnerHTML={{
                            __html: `
                                    !function(f,b,e,v,n,t,s)
                                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                                    n.queue=[];t=b.createElement(e);t.async=!0;
                                    t.src=v;s=b.getElementsByTagName(e)[0];
                                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                                    'https://connect.facebook.net/en_US/fbevents.js');
                                    fbq('init', '4981326851954035');
                                    fbq('track', 'PageView');`,
                        }}
                    />
                    <Script
                        id="hotjar"
                        strategy="lazyOnload"
                        async={true}
                        dangerouslySetInnerHTML={{
                            __html: `(function(h,o,t,j,a,r){
                                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                    h._hjSettings={hjid:2994035,hjsv:6};
                                    a=o.getElementsByTagName('head')[0];
                                    r=o.createElement('script');r.async=1;
                                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                    a.appendChild(r);
                                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`,
                        }}
                    />
                    <Script
                        id={'Moengage'}
                        strategy="lazyOnload"
                        async={true}
                        dangerouslySetInnerHTML={{
                            __html: `!function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");
                              Moengage = moe({
                                  app_id:"Z4EUJCJFD9SZ5NQQ4AWHUS80",
                                  debug_logs: 0,
                                  cluster: "DC_2",
                                  swPath: "/serviceworker.js"
                              });`,
                        }}
                    />
                    {/* WhatsApp Integration Script */}
                    <Script
                        id="whatsapp-widget"
                        strategy="lazyOnload"
                        dangerouslySetInnerHTML={{
                            __html: `
                                var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?39556';
                                var s = document.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = url;
                                var options = {
                                    "enabled": true,
                                    "chatButtonSetting": {
                                        "backgroundColor": "#00e785",
                                        "ctaText": "Chat with us",
                                        "borderRadius": "25",
                                        "marginLeft": "0",
                                        "marginRight": "20",
                                        "marginBottom": "20",
                                        "ctaIconWATI": false,
                                        "position": "right"
                                    },
                                    "brandSetting": {
                                        "brandName": "We Make Footballers",
                                        "brandSubTitle": "undefined",
                                        "brandImg": "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
                                        "welcomeText": "Hi there!\\nHow can I help you?",
                                        "messageText": "Hello, %0A I have a question about {{page_link}}",
                                        "backgroundColor": "#00e785",
                                        "ctaText": "Chat with us",
                                        "borderRadius": "25",
                                        "autoShow": false,
                                        "phoneNumber": "12513123150"
                                    }
                                };
                                s.onload = function() {
                                    CreateWhatsappChatWidget(options);
                                };
                                var x = document.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                            `,
                        }}
                    />
                </>
            ) : null}

            {configEnv === 'staging' ? (
                <>
                    <Script
                        id={'Moengage'}
                        strategy="lazyOnload"
                        async={true}
                        dangerouslySetInnerHTML={{
                            __html: `!function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");
                              Moengage = moe({
                                  app_id:"Z4EUJCJFD9SZ5NQQ4AWHUS80",
                                  debug_logs: 1,
                                  cluster: "DC_2",
                                  swPath: "/serviceworker.js"
                              });`,
                        }}
                    />
                    {/* WhatsApp Integration Script */}
                    <Script
                        id="whatsapp-widget-staging"
                        strategy="lazyOnload"
                        dangerouslySetInnerHTML={{
                            __html: `
                                var url = 'https://wati-integration-prod-service.clare.ai/v2/watiWidget.js?39556';
                                var s = document.createElement('script');
                                s.type = 'text/javascript';
                                s.async = true;
                                s.src = url;
                                var options = {
                                    "enabled": true,
                                    "chatButtonSetting": {
                                        "backgroundColor": "#00e785",
                                        "ctaText": "Chat with us",
                                        "borderRadius": "25",
                                        "marginLeft": "0",
                                        "marginRight": "20",
                                        "marginBottom": "20",
                                        "ctaIconWATI": false,
                                        "position": "right"
                                    },
                                    "brandSetting": {
                                        "brandName": "We Make Footballers",
                                        "brandSubTitle": "undefined",
                                        "brandImg": "https://www.wati.io/wp-content/uploads/2023/04/Wati-logo.svg",
                                        "welcomeText": "Hi there!\\nHow can I help you?",
                                        "messageText": "Hello, %0A I have a question about {{page_link}}",
                                        "backgroundColor": "#00e785",
                                        "ctaText": "Chat with us",
                                        "borderRadius": "25",
                                        "autoShow": false,
                                        "phoneNumber": "12513123150"
                                    }
                                };
                                s.onload = function() {
                                    CreateWhatsappChatWidget(options);
                                };
                                var x = document.getElementsByTagName('script')[0];
                                x.parentNode.insertBefore(s, x);
                            `,
                        }}
                    />
                </>
            ) : null}

            {configEnv === 'local' ? <></> : null}
        </>
    );
}

import { NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Constants from 'src/common/Constants';
import usePermissionLocation from 'src/hooks/usePermissionLocation';
import WMFScripts from '../services/WMFScripts';

const Footer = dynamic(() => import('./Footer'), { ssr: true });
const Header = dynamic(() => import('./Header'), { ssr: true });

const LocationModal = dynamic(
    () => import('src/components/include/LocationModal'),
    { ssr: true },
);

const DEFAULT_LAT = 51.5285582;
const DEFAULT_LOG = -0.2416794;

const DEFAULT_LAT_US = 26.264201;
const DEFAULT_LOG_US = -81.7440038;

const DefaultLayout = (props) => {
    const { seo, children } = props;

    const country =
        useSelector((state) => state.headerReducer.country) || Constants.UK;
    const locationStatus = usePermissionLocation();

    useEffect(() => {
        if (!props.isGeolocationAvailable || !props.isGeolocationEnabled) {
            localStorage.removeItem('latitude');
            localStorage.removeItem('longitude');

            localStorage.setItem(
                'latitude',
                country === Constants.UK ? DEFAULT_LAT : DEFAULT_LAT_US,
            );
            localStorage.setItem(
                'longitude',
                country === Constants.UK ? DEFAULT_LOG : DEFAULT_LOG_US,
            );
        }

        if (props.coords) {
            localStorage.setItem('latitude', props.coords.latitude);
            localStorage.setItem('longitude', props.coords.longitude);
        }
    }, [props]);

    const currentLocation = process.browser ? window.location.href : null;
    const url = process.env.NEXT_PUBLIC_NEXTJS_SITE_URL
        ? process.env.NEXT_PUBLIC_NEXTJS_SITE_URL
        : currentLocation;

    return (
        <>
            <Header />
            <WMFScripts />
            <NextSeo
                title={
                    seo?.title ||
                    `Professional Football Coaching-Ages 4-12 | We Make
                    Footballers`
                }
                description={
                    seo?.content ||
                    `We Make Footballers is a UK wide football coaching company for kids aged 4 to 12 of all abilties. We offer professional and fun training to help children develop their football skills.`
                }
                canonical={seo?.canonical || url || ''}
                noindex={seo?.noindex === 1}
            />
            {children}
            <Footer />
            <LocationModal />
        </>
    );
};

export default DefaultLayout;
